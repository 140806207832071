import React, { memo } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { getIsSupportedBrowser } from 'redux/modules/browserInfo/selectors';

import ExperienceFragmentHOC from 'components/ExperienceFragment/ExperienceFragmentHoC';
import { SITE_WIDE_BANNER } from 'constants/experience-fragments';
import { useUrgencyBannerType } from 'components/UrgencyBanner/useUrgencyBannerType';
import siteBannerMessageComponent from './SiteBannerExperienceFragment';
import UnsupportedBrowserBanner from './UnsupportedBrowserBanner';

const CommonSiteBanner = memo(({ isSupportedBrowser, show }) => {
  const noUrgencyBanner = useUrgencyBannerType()[0] === null;

  const SiteBannerMessage = React.useMemo(
    () =>
      isSupportedBrowser && show && noUrgencyBanner
        ? ExperienceFragmentHOC(siteBannerMessageComponent)
        : null,
    [isSupportedBrowser, show, noUrgencyBanner],
  );

  if (!isSupportedBrowser) {
    return UnsupportedBrowserBanner;
  }

  if (show && noUrgencyBanner) {
    return (
      <SiteBannerMessage
        locationName={SITE_WIDE_BANNER.location}
        experienceFragmentKey={SITE_WIDE_BANNER.key}
      />
    );
  }

  return null;
});

CommonSiteBanner.propTypes = {
  isSupportedBrowser: propTypes.bool.isRequired,
  show: propTypes.bool,
};

CommonSiteBanner.defaultProps = {
  show: true,
};

const mapStateToProps = state => ({
  isSupportedBrowser: getIsSupportedBrowser(state),
});

export default connect(mapStateToProps)(CommonSiteBanner);
