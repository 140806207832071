import React from 'react';
import propTypes from 'prop-types';
import { SitewideInPage } from '@johnlewispartnership/wtr-ingredients/ingredients/Sitewide/InPage';
import RichText from '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/RichText';
import {
  CONTAINER,
  RICH_TEXT,
} from '@johnlewispartnership/wtr-content-component-library/dist/component-library/constants/cmsResourceTypes';

const SiteBannerExperienceFragment = ({ locationData }) => {
  if (!locationData?.length) {
    return null;
  }

  const [containerComponent] = locationData;

  if (containerComponent.resourceType !== CONTAINER) {
    return null;
  }

  const {
    backgroundColor,
    childComponents: [grid],
  } = containerComponent;

  const {
    childComponents: [richTextComponent = {}],
  } = grid;

  if (richTextComponent?.resourceType !== RICH_TEXT) {
    return null;
  }

  return (
    <SitewideInPage
      variant="offer"
      className="no-print"
      style={{ backgroundColor }}
      data-testid="site-banner"
    >
      <RichText {...richTextComponent} noMargin position="site-banner" />
    </SitewideInPage>
  );
};

SiteBannerExperienceFragment.defaultProps = {
  locationData: [],
};

SiteBannerExperienceFragment.propTypes = {
  locationData: propTypes.arrayOf(
    propTypes.shape({
      resourceType: propTypes.string,
    }),
  ),
};

export default SiteBannerExperienceFragment;
